import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
const PROGRESS_STEPS_FIELDS = [
    { key: 'index', label: '#', thStyle: { width: '60px' } },
    { key: 'name', label: 'Name' },
    { key: 'status', label: 'Status' },
    // Comparison
    { key: 'comparison', label: 'Comparison' },
    // Merge
    { key: 'merge', label: 'Merge' },
    // Backup
    { key: 'backup', label: 'Backup' },
    // Restore
    { key: 'restore', label: 'Restore' },
    { key: 'restoreRecursiveReferences', label: 'Restore Recursive References' },
    { key: 'restoreCrossReferences', label: 'Restore Cross References' },
    // { key: 'updateStatus', label: 'Update Status' },
    { key: 'deploy', label: 'Deploy' },
    { key: 'retrieve', label: 'Retrieve' },
];
let Logs = class Logs extends Vue {
    constructor() {
        super(...arguments);
        this.isAutoScroll = true;
        this.isLogDetailsShown = true;
        this.isProgressStepsShown = true;
    }
    get fields() {
        return PROGRESS_STEPS_FIELDS.filter(field => this.shownColumns.includes(field.key));
    }
    get logsStyles() {
        let progressStepsRowValue = '2fr';
        let logDetailsRowValue = '1fr';
        if (this.isProgressStepsShown && !this.isLogDetailsShown) {
            progressStepsRowValue = '1fr';
            logDetailsRowValue = 'fit-content(100%)';
        }
        else if (!this.isProgressStepsShown && this.isLogDetailsShown) {
            progressStepsRowValue = 'fit-content(100%)';
            logDetailsRowValue = '1fr';
        }
        else if (!this.isProgressStepsShown && !this.isLogDetailsShown) {
            progressStepsRowValue = 'fit-content(100%) fit-content(100%)';
        }
        return {
            gridTemplateRows: `${progressStepsRowValue} ${logDetailsRowValue}`,
        };
    }
    toggleLogDetails() {
        this.isLogDetailsShown = !this.isLogDetailsShown;
    }
    toggleProgressSteps() {
        this.isProgressStepsShown = !this.isProgressStepsShown;
    }
    downloadLogDetails() {
        const element = document.createElement('a');
        element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(this.logDetails)}`);
        element.setAttribute('download', 'Log.txt');
        element.click();
    }
    onLogDetailsChanged() {
        if (this.isAutoScroll) {
            const ref = this.$refs.logDetailsRef;
            const element = ref.$el;
            window.setTimeout(() => {
                element.scrollTop = element.scrollHeight;
            }, 100);
        }
    }
};
__decorate([
    Prop({
        default: () => [],
    })
], Logs.prototype, "shownColumns", void 0);
__decorate([
    Prop({
        default: '',
    })
], Logs.prototype, "jobType", void 0);
__decorate([
    Prop({
        default: '',
    })
], Logs.prototype, "logDetails", void 0);
__decorate([
    Prop({
        default: () => [],
    })
], Logs.prototype, "progressSteps", void 0);
__decorate([
    Prop({
        default: false,
    })
], Logs.prototype, "isDone", void 0);
__decorate([
    Watch('logDetails')
], Logs.prototype, "onLogDetailsChanged", null);
Logs = __decorate([
    Component({ components: {} })
], Logs);
export default Logs;
