import request from '@/services/axios-instance';
export default class LogsApi {
    static fetchLogDetail(jobId, executionId) {
        return request
            .get(`/logs/log-detail`, {
            params: {
                jobId,
                executionId,
            },
        })
            .then(({ data }) => data);
    }
    static fetchLog(logId, logFileId) {
        return request
            .get('/logs/logs', {
            params: {
                logId,
                logFileId,
            },
        })
            .then(({ data }) => data);
    }
}
