var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logs",style:(_vm.logsStyles)},[_c('div',{staticClass:"logs-card"},[_c('div',{staticClass:"logs-card__title",on:{"click":_vm.toggleProgressSteps}},[_c('span',[_vm._v("Progress steps")]),_c('f-icon',{staticClass:"logs-card__icon",attrs:{"name":_vm.isProgressStepsShown ? 'chevron_up' : 'chevron_down'}})],1),(_vm.isProgressStepsShown)?_c('div',{staticClass:"logs-card__body scroll-wrapper"},[_c('f-loading',{attrs:{"shown":!_vm.progressSteps.length && !_vm.isDone,"small":true}}),_c('f-overlay',{attrs:{"shown":_vm.isDone && !_vm.progressSteps.length}},[_vm._v("There is no progress steps to be shown.")]),_c('f-table',{attrs:{"fields":_vm.fields,"items":_vm.progressSteps,"sticky-header":"100%"},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1)+" ")]}},{key:"cell(backup)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"successful"},[_vm._v(" "+_vm._s(item.state.backup && item.state.backup.successful > -1 ? item.state.backup.successful : '--')+" ")])]}},{key:"cell(restore)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"successful"},[_vm._v(" "+_vm._s(item.state.restore && item.state.restore.successful > -1 ? item.state.restore.successful : '--')+" ")]),_vm._v("  /  "),_c('span',{staticClass:"failed"},[_vm._v(" "+_vm._s(item.state.restore && item.state.restore.failed > -1 ? item.state.restore.failed : '--')+" ")])]}},{key:"cell(restoreRecursiveReferences)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"successful"},[_vm._v(" "+_vm._s(item.state.restoreRecursiveReferences && item.state.restoreRecursiveReferences.successful > -1 ? item.state.restoreRecursiveReferences.successful : '--')+" ")]),_vm._v("  /  "),_c('span',{staticClass:"failed"},[_vm._v(" "+_vm._s(item.state.restoreRecursiveReferences && item.state.restoreRecursiveReferences.failed > -1 ? item.state.restoreRecursiveReferences.failed : '--')+" ")])]}},{key:"cell(restoreCrossReferences)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"successful"},[_vm._v(" "+_vm._s(item.state.restoreCrossReferences && item.state.restoreCrossReferences.successful > -1 ? item.state.restoreCrossReferences.successful : '--')+" ")]),_vm._v("  /  "),_c('span',{staticClass:"failed"},[_vm._v(" "+_vm._s(item.state.restoreCrossReferences && item.state.restoreCrossReferences.failed > -1 ? item.state.restoreCrossReferences.failed : '--')+" ")])]}},{key:"cell(comparison)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"successful"},[_vm._v(" "+_vm._s(item.state.comparison && item.state.comparison.successful > -1 ? item.state.comparison.successful : '--')+" ")]),(_vm.jobType !== 'Comparison Backup To Backup')?[_vm._v("  /  "),_c('span',{staticClass:"failed"},[_vm._v(" "+_vm._s(item.state.comparison && item.state.comparison.failed > -1 ? item.state.comparison.failed : '--')+" ")])]:_vm._e()]}},{key:"cell(merge)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"successful"},[_vm._v(" "+_vm._s(item.state.merge && item.state.merge.successful > -1 ? item.state.merge.successful : '--')+" ")]),_vm._v("  /  "),_c('span',{staticClass:"failed"},[_vm._v(" "+_vm._s(item.state.merge && item.state.merge.failed > -1 ? item.state.merge.failed : '--')+" ")])]}},{key:"cell(deploy)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"successful"},[_vm._v(" "+_vm._s(item.state.deploy && item.state.deploy.successful > -1 ? item.state.deploy.successful : '--')+" ")]),_vm._v(" / "),_c('span',{staticClass:"failed"},[_vm._v(" "+_vm._s(item.state.deploy && item.state.deploy.failed > -1 ? item.state.deploy.failed : '--')+" ")])]}},{key:"cell(retrieve)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"successful"},[_vm._v(" "+_vm._s(item.state.retrieve && item.state.retrieve.successful > -1 ? item.state.retrieve.successful : '--')+" ")]),_vm._v(" / "),_c('span',{staticClass:"failed"},[_vm._v(" "+_vm._s(item.state.retrieve && item.state.retrieve.failed > -1 ? item.state.retrieve.failed : '--')+" ")])]}}],null,false,102490235)})],1):_vm._e()]),_c('div',{staticClass:"logs-card"},[_c('div',{staticClass:"logs-card__title",on:{"click":_vm.toggleLogDetails}},[_c('span',[_vm._v("Log details")]),_c('f-icon',{staticClass:"logs-card__icon",attrs:{"name":_vm.isLogDetailsShown ? 'chevron_up' : 'chevron_down'}})],1),(_vm.isLogDetailsShown)?_c('div',{staticClass:"logs-card__body logs-card__body--log-details"},[_c('f-loading',{attrs:{"shown":!_vm.logDetails && !_vm.isDone,"small":true}}),_c('f-overlay',{attrs:{"shown":_vm.isDone && !_vm.logDetails}},[_vm._v("There is no logs to be shown.")]),_c('b-textarea',{ref:"logDetailsRef",staticClass:"logs__log-input",attrs:{"plaintext":"","no-resize":""},model:{value:(_vm.logDetails),callback:function ($$v) {_vm.logDetails=$$v},expression:"logDetails"}}),_c('div',{staticClass:"d-flex align-items-center justify-content-between mt-3"},[_c('f-checkbox',{staticClass:"logs__checkbox",model:{value:(_vm.isAutoScroll),callback:function ($$v) {_vm.isAutoScroll=$$v},expression:"isAutoScroll"}},[_vm._v("Autoscroll with output")]),_c('b-button',{staticClass:"flosum-btn",on:{"click":_vm.downloadLogDetails}},[_vm._v("Download")])],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }