import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import Logs from '@/components/shared/Logs.vue';
import LogsApi from '@/services/api/logs.api';
let LogsPage = class LogsPage extends Vue {
    constructor() {
        super(...arguments);
        this.LOG_FETCH_TIMEOUT = 2500;
        this.LOG_DETAIL_FETCH_TIMEOUT = 2500;
        this.jobType = this.$route.query.jobType;
        this.jobId = this.$route.query.jobId ? +this.$route.query.jobId : undefined;
        this.executionId = this.$route.query.executionId
            ? +this.$route.query.executionId
            : undefined;
        this.isDestroyed = false;
        this.isDone = false;
        this.logDetails = '';
        this.progressSteps = [];
    }
    get shownColumns() {
        const baseFields = ['index', 'name', 'status'];
        switch (this.jobType) {
            case 'Comparison':
            case 'Comparison Backup To Backup':
                return [...baseFields, 'comparison'];
            case 'Create Backup':
            case 'Full Backup':
            case 'Template Backup':
            case 'Incremental Backup':
                return [...baseFields, 'backup'];
            case 'Restore Backup':
            case 'Restore Comparison':
            case 'Restore Comparison Backup To Backup':
            case 'Restore Comparison Backup To Org':
                return [...baseFields, 'restore', 'restoreRecursiveReferences', 'restoreCrossReferences', 'updateStatus'];
            case 'Merge':
                return [...baseFields, 'restore'];
            case 'Deploy Backup To Storage':
                return [...baseFields, 'deploy'];
            case 'Retrieve Backup From Storage':
                return [...baseFields, 'retrieve'];
            default:
                return baseFields;
        }
    }
    mounted() {
        this.initLogs();
    }
    goBack() {
        this.$router.back();
    }
    beforeDestroy() {
        this.isDestroyed = true;
    }
    async initLogs() {
        if (this.isDestroyed) {
            return;
        }
        try {
            const logDetail = await LogsApi.fetchLogDetail(this.jobId, this.executionId);
            if (!logDetail.logId || !logDetail.logFileId) {
                window.setTimeout(() => this.initLogs(), this.LOG_DETAIL_FETCH_TIMEOUT);
                return;
            }
            this.$nextTick(() => {
                this.fetchLogs(logDetail.logId, logDetail.logFileId);
            });
        }
        catch (error) {
            this.$toast.error(error.message);
        }
    }
    async fetchLogs(id, fileId) {
        if (this.isDestroyed) {
            return;
        }
        try {
            const { log, jobStatus, executionStatus } = await LogsApi.fetchLog(id, fileId);
            if (Array.isArray(log)) {
                this.$toast.error('Logs are incompatible with current implementation.');
                return;
            }
            log.messages ||= [];
            log.progressStates ||= [];
            this.progressSteps = log.progressStates;
            this.logDetails = log.messages
                .map(item => (typeof item === 'string' ? item : item.messages))
                .filter(item => (typeof item === 'string' ? item : item.length > 0))
                .map(item => {
                if (typeof item === 'string') {
                    return item;
                }
                item.unshift('\n');
                return item;
            })
                .flat()
                .join('\n');
            if (jobStatus !== 'In Progress' && executionStatus !== 'In Progress') {
                this.isDone = true;
                return;
            }
            window.setTimeout(() => {
                this.fetchLogs(id, fileId);
            }, this.LOG_FETCH_TIMEOUT);
        }
        catch (error) {
            this.$toast.error(error.message);
        }
    }
};
LogsPage = __decorate([
    Component({
        components: {
            Logs,
        },
    })
], LogsPage);
export default LogsPage;
